<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">

    <div id="page-content" v-if="!viLoader">
      
      <!-- <div class="dispflex" v-if="$route.path == '/admission/academicYear'" >
        <h1 class="hdrtitle" style="margin-top: 0px;">School Hub</h1>
         <button type="button" class="backbtn" @click="previousRoute">
               <i class="fa fa-arrow-left"></i>
              </button>
      </div> -->
      <div class="webNew-dispflex" v-if="$route.path == '/admission/academicYear'"  >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
     </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        School Hub
       </h4>
     </div>
       
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex" v-if="$route.path == '/admission/academicYear'">
              <div style="width: auto">
                <span class="iconsect blubg" 
                  ><i class="far fa-calendar-alt"></i
                ></span>
                <h1>View Academic Year</h1>
              </div>
              <div style="margin-top:10px"> 
                <span class="has-float-label" style="width: 127%;margin-left: 3px;">
                   <select
										class="form-control form-input"
										id="deptlistid"
										@change="getSelectRange"
										v-model="selectAcademicYear"
										>
										<option :value=null>-- Select --</option>
										<option v-for="(item,index) in academicYearList" :key="index" :value="item._id">
											{{ item.from}} - {{ item.to }}
										</option>
										</select>
                    <label for="clslistid">Select Academic Year </label>
              </span>
              </div>
              <div class="mt-1">
                
                <button class="btn gry_bg mpopup" v-b-modal.addacademicyr>
                  <i class="fas fa-calendar-plus"></i>
                </button>
              </div>
            </div>
            <div class="widjetcontent" v-if="!viLoader">
              <div class="restable">
                <div class="resrow resheader">
                  <div class="cell">From</div>
                  <div class="cell">To</div>
                  <div class="cell">Current Academic Year</div>
                  <div class="cell">Conclude Academic Year</div>
                  <!-- <div class="cell">Last Modified By</div>
                   <div class="cell">Last Modified Date</div> -->
                  <div class="cell">Action</div>
                </div>

                <div
                  class="resrow"
                  v-for="(academicYear, index) in academicYearList"
                  :key="index"
                >
                 
                  <div class="cell" data-title="From Month">
                    {{ academicYear.from }}
                  </div>
                  <div class="cell" data-title="From Year">
                    {{ academicYear.to }}
                  </div>
                   <div class="cell" data-title="From Year" :style="academicYear.isDeleted ? 'color: green;padding-left: 5%;' : 'color: red;padding-left: 5%;'" >
                    {{ academicYear.isDeleted ? 'Yes' : 'No' }}
                  </div>
                  <div class="cell" data-title="From Year" style="padding-left: 5%;" >
                    {{ academicYear.isConclude ? 'Yes' : 'No' }}
                  </div>
                  <!-- <div class="cell" data-title="From Year" :style="currentAcademicYear._id == academicYear._id ? 'color: green;padding-left: 5%;' : 'color: red;padding-left: 5%;'" >
                    {{ currentAcademicYear._id == academicYear._id ? 'Yes' : 'No' }}
                  </div> -->
                  
                   <!-- <div class="cell" data-title="getUserName">
                    {{ academicYear.updatedBy ? academicYear.updatedBy.firstName + " " +  academicYear.updatedBy.lastName : '' }}
                  </div>
                  <div  class="cell" data-title="Role">
                        {{ academicYear.updatedDate | dateFormat }}
                  </div> -->
                  <div class="cell" data-title="Action">
                    <div
                            class="dispflex"
                            style="justify-content: stretch"
                          >
                            <b-dropdown
                              id="dropdown-1"
                              text=""
                              style="margin-right: 3px"
                            >
                            <b-dropdown-item @click="setCurrentAcademicYearActive(academicYear._id)" v-if="!academicYear.isDeleted"
                              
                              >Set Current Year</b-dropdown-item
                            >
                              <b-dropdown-item @click="showConcludeModal(academicYear._id)" v-if="!academicYear.isConclude"
                              
                                >Conclude to End</b-dropdown-item
                              >
                              <b-dropdown-item @click="showConcludeActiveModal(academicYear._id)" v-if="academicYear.isConclude"
                              
                                >Conclude to Active</b-dropdown-item
                              >
                              
                            </b-dropdown>
                    <button
                      class="btn round orange tooltipt"
                      v-b-modal.addacademicyr
                      @click="showUpdateModal(index)"
                    >
                      <i class="fas fa-edit"></i
                      ><span class="tooltiptext">Edit</span>
                    </button>
                    <button :disabled="academicYear.isDeleted"
                      class="btn round red tooltipt"
                      @click="showDeleteModal(academicYear._id)"
                    >
                      <i class="far fa-trash-alt"></i>
                      <span class="tooltiptext">Delete</span>
                    </button>
                  </div>
                </div>
                </div>
              </div>
            </div>

            

            <div class="widjetfooter text-right dk_iconsml" v-if="$route.path == '/admission/academicYear'">
             
              <div class="text-center mt-4" v-if="academicYearList.length > 0">
                <div class="pagination" id="pagination">
                  <a  :style="currentPageNumber === 1 ? 'pointer-events: none;' : ''" @click="goToPreviousPage">&laquo;</a>
                  <a
                    v-for="(item, index) in lastPageNumber"
                    :key="index"
                    v-on:click="selected = item"
                    v-bind:class="{ active: selected == item }"
                    @click="navToSelectedPage(item)"
                    >{{ item }}</a
                  >
                  <a  :style="currentPageNumber === lastPageNumber ? 'pointer-events: none;' : ''" @click="goToNextPage">&raquo;</a>
                </div>
              </div>
           </div>
          </div>
        </div>
      </div>
    </div>
    <div>
    <b-modal
      id="addacademicyr"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
    <!-- <button
    type="button" class="close" 
                    >
                      x<span class="tooltiptext">Clsoe</span>
                    </button> -->
    <button type="button" class="close"  @click.prevent="closePoup">×</button>
      <div class="contact-form">
        <h2>{{ isUpdate ? 'Update Academic Year' : 'Create Academic Year' }}</h2>
       
        <br />
        <div>
          <form data-vv-scope="academicyr" id="academicyr" accept-charset="utf-8">
            <div style="color:#6c757d;padding-left:6px;mt-3">
              Academic Session <span class="required">*</span>
            </div>
            <div class="form-row mt-2">
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                 
                  <vue-monthly-picker
                    @input="removeTodate(academicYear.from)"
                    name="from"
                    v-validate="'required'"
                    v-model="academicYear.from"
                    :monthLabels="monthOptions"
                    placeHolder="Select from [Month & Year]"
                  >
                  </vue-monthly-picker>
                  <label
                          v-if="errors.first('academicyr.from') && showErrorMsg"
                          class="vi-error"
                          for="pwdid"
                          style="top:-1em"
                          >Please Select from [Month & Year]</label
                        >
                        <label for="pwdid" v-else
                          >From <span class="required">*</span></label
                        >
                </span>

              </div>

              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <vue-monthly-picker
                    @input="setToDate"
                    :disabled="!academicYear.from"
                    name="to"
                    v-validate="'required'"
                    v-model="academicYear.to"
                    :monthLabels="monthOptions"
                    placeHolder="Select to [Month & Year]"
                  >
                  </vue-monthly-picker>
                       <label
                        v-if="errors.first('academicyr.to') && showErrorMsg"
                        class="vi-error"
                        for="pwdid"
                        style="top:-1em"
                        >Please Select to [Month & Year]</label>

                        <label
                        v-else-if="minMonth"
                        class="vi-error"
                        for="pwdid"
                        style="top:-1em"
                        >Academic Year duration must be atleast 6 months</label>

                        <label
                        v-else-if="maxMonth"
                        class="vi-error"
                        for="pwdid"
                        style="top:-1em"
                        >Duration must be between 6 to 12 months</label>

                        <label
                        v-else-if="inBetweenMonth"
                        class="vi-error"
                        for="pwdid"
                        style="top:-1em"
                        >Academic Year Must be 6 months or 12 months</label>

                        <label for="pwdid" v-else
                          >To <span class="required">*</span></label
                        > 
         
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="isUpdate">
                <span class="has-float-label">
                  <input
                    type="text"
                    disabled
                    name="studentFirstName"
                    v-model="lastmodify"
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                 
                  <label for="stdFirstName"
                    >Last Modified By </label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 pr-md-3 mt-3" v-if="isUpdate">
                <span class="has-float-label">
                  <input
                    type="text"
                    disabled
                    name="studentFirstName"
                    v-model="lastModifyDate"
                    class="form-control form-input"
                    id="stdFirstName"
                    placeholder="Middle Name"
                  />
                 
                  <label for="stdFirstName"
                    >Last Modified Date</label
                  >
                </span>
              </div>
             
            </div>
            <span class="required" style="margin-left: 277px;font-size: 14px;">* Note: Academic Year duration must be between 6 to 12 months</span>

            <div
              class="mt-3 pt-3"
             
            >
              
              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  style="margin-right: 8px;width: 114px;"
                  @click.prevent="createAcademicYear"
                  v-if="!isUpdate"
                >
                  <span>Save</span>
                </button>
                <button
                  type="button"
                  style="width: 107px;"
                  @click.prevent="closePoup"
                  class="btn btncl clsmdl" 
                  id="clbtn"
                  v-if="!isUpdate"
                >
                 <span>Cancel</span>
                </button>
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  style="margin-right: 8px;width: 114px;"
                  v-if="isUpdate"
                  @click.prevent="updateAcademicYear"
                >
                  <span>Update</span>
                </button>
                <button
                  type="button"
                  @click.prevent="closeUpdateModal"
                  style="width: 107px;"
                  class="btn btncl clsmdl" 
                  id="clbtn"
                  v-if="isUpdate"
                >
                  <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div style="display: none">
          <form action="" method="post" id="createfield" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="lblname"
                    value=""
                    class="form-control form-input"
                    id="lblid"
                    placeholder="Label Name"
                    required="required"
                  />
                  <label for="lblid"
                    >Label Name <span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-6 mt-3">
                <span class="has-float-label">
                  <input
                    list="dtypelist"
                    value=""
                    class="form-control form-input"
                    id="dtypeid"
                    placeholder="Data Type"
                    required="required"
                  />
                  <label for="dtypeid"
                    >Data Type <span class="required">*</span></label
                  >
                  <datalist id="dtypelist">
                    <option value="String">String</option>
                    <option value="Number">Number</option>
                    <option value="Boolean">Boolean</option>
                  </datalist>
                </span>
              </div>
              <div
                class="form-group input-group col-md-12 mt-3 pr-md-3"
                style="border-bottom: 1px solid #e5e5e5"
              >
                <label style="color: #6c757d" class="mr-3"
                  >Is Mandatory<span class="required">*</span></label
                >
                <label class="custradio"
                  >Yes
                  <input type="radio" name="radio" />
                  <span class="checkmark"></span>
                </label>
                <label class="custradio"
                  >No
                  <input type="radio" name="radio" />
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>

            <div
              class="pt-3 text-right dk_iconsml"
              style="border-top: 1px solid #e5e5e5"
            >
              <button
                type="button"
                class="btn btnsml"
                id="svbtn"
                onclick="saveForm()"
              >
                  <span>Save</span>
              </button>
              <button type="button" class="btn btncl clsmdl">
                   <span>Cancel</span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    <b-modal id="deleteAcademicYearPopup" >
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Delete Confirmation</h2>
        <br />
        Are you sure you want to delete this record?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
        <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  style="margin-right: 8px;width: 114px;"
                  @click.prevent="confirmDelete"
                >
                  <span>Confirm</span>
                </button>
                <button
                  type="button"
                  @click.prevent="closeDeletePopUp"
                  style="width: 107px;"
                  class="btn btncl clsmdl" 
                  id="clbtn"
                >
                  <span>Cancel</span>
                </button>

          <!-- <button
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="confirmDelete"
          >
              <span>Confirm</span>
          </button> -->
          <!-- <button @click.prevent="closeDeletePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button> -->
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    
    <b-modal id="concludeAcademicYearPopup" >
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Conclude Confirmation</h2>
        <br />
        Your Academic Year Session will be ended,  are you sure you want to end the session?
        <div
          class="pt-3 text-right dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            type="button"
            class="btn btnsml"
            id="svbtn"
            style="width: 127px;"
            @click.prevent="confirmConclude"
          >
              <span>Confirm</span>
          </button>
          <button @click.prevent="closeConcludePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    
    <b-modal id="concludeActiveAcademicYearPopup" >
      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Conclude Active Confirmation</h2>
        <br />
        Your Academic Year Session will be Active,  are you sure you want to active the session?
        <div
          class="pt-3 text-right dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            type="button"
            class="btn btnsml"
            id="svbtn"
            style="width: 127px;"
            @click.prevent="confirmActiveConclude"
          >
              <span>Confirm</span>
          </button>
          <button @click.prevent="closeActiveConcludePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    </div>
     <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
     />
    
  </div>
</template>
<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import VueMonthlyPicker from "vue-monthly-picker";

export default {
  name: "Class",
  components: {
    ViSpinner,
    VueMonthlyPicker,
  },
  data() {
    return {
      academicYear: {
        _id: null,
        from: null,
        to: null,
      },
      lastmodify: null,
      lastModifyDate: null,
      viLoader: false,
      minMonth : false,
      maxMonth : false,
      showErrorMsg: false,
      inBetweenMonth: false,
      searchWords: '',
      skip: 0, // set the skip info
      limit: 8, // set the limit info
      countAll: true,
      totalCount: 0,
      academicYearList: [
        {
          isSelected: false,
          fromMonth: "March",
          fromYear: 2020,
          toMonth: "December",
          toYear: 2021,
        },
      ],

      isUpdate: false,
      deleteId: null,
      concludeId: null,
      monthOptions: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
    };
  },
  created() {
    this.getAcademicYearList('new');
  },
   computed: {
      styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      },
      getUserName(){
        let userData = secureUI.sessionGet("user");
        return userData.userDetails.firstName + " " + userData.userDetails.lastName
      },
      currentAcademicYear() {
      let getDetails = this.$store.getters.getCurrentAcademicYear;
    
      if (getDetails) {
        return getDetails
      }else{
        return ''
      }
    },
     
  },
  watch: {
          searchWords: function (currentVal, oldVal) {
               let hasCurrentParam = currentVal || '';
               let hasOldParam = oldVal || '';

              if (hasCurrentParam != hasOldParam) {
                   this.skip = 0
                   this.currentPageNumber = 1,
                   this.lastPageNumber = 1,
                   this.selected = 1,
                   this.totalCount = 0,
                   this.countAll = true
                   this.getAcademicYearList('old');
              }
          }
  },
  methods: {
    previousRoute(){
    let userData = secureUI.sessionGet("user");
    
    if(userData.userDetails.account.version == 'freeVersion'){
     localStorage.setItem("activeTab",'/enrollment/school');
     localStorage.setItem("previousTab",'/enrollment/school');
     this.$router.push({ path: '/enrollment/school' });
    }else{
        localStorage.setItem("activeTab", '/admission/home');
        localStorage.setItem("previousTab", '/admission/home');
        this.$router.push({ path: '/admission/home' });
    }
    
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },

    showdrop() {
      var showdp = document.getElementById("showdropmenu");
      /*if (showdp.style.display === "block") {
        showdp.style.display = "none";
      } else {
        showdp.style.display = "block";
      }*/
      document.getElementById("showdropmenu").classList.toggle("show");
    /*  setTimeout(function() {
        showdp.style.display = "none";
      }, 10000);
      return false;*/
    },
    

    showUpdateModal(index) {
      let data = this.academicYearList[index];
      
      this.academicYear._id = data._id;
      this.academicYear.from = data.from;
      this.academicYear.to = data.to;

    //  {{ academicYear.updatedBy updatedDate? academicYear.updatedBy.firstName + " " +  academicYear.updatedBy.lastName : '' }}

      this.isUpdate = true;
      this.lastmodify = data.updatedBy ? data.updatedBy.firstName + " " +  data.updatedBy.lastName : '';
      this.lastModifyDate = data.updatedDate ? data.updatedDate : ''
    },

    showDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show("deleteAcademicYearPopup");
    },
    showConcludeModal(id) {
      this.concludeId = id;
      this.$bvModal.show("concludeAcademicYearPopup");
    },
    showConcludeActiveModal(id) {
      this.concludeId = id;
      this.$bvModal.show("concludeActiveAcademicYearPopup");
    },
    closeActiveConcludePopUp(){
      this.$bvModal.hide("concludeActiveAcademicYearPopup");
      this.concludeId = null;
    },
    
    
    setToDate(){

        this.minMonth = false,
        this.maxMonth = false
        this.inBetweenMonth = false
      
        var d1 = this.isUpdate ? new Date(this.academicYear.from) : new Date(this.academicYear.from._i)
        var d2 = new Date(this.academicYear.to._i)
      
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();

        let getMonths = months <= 0 ? 0 : months + 1


        if(getMonths < 6){
          this.minMonth = true,
          this.maxMonth = false
          this.inBetweenMonth = false
        }
        if(getMonths > 12){
          this.minMonth = false,
          this.maxMonth = true,
          this.inBetweenMonth = false
        }
      
    },
    removeTodate(from){

        this.academicYear.to = null;
        this.minMonth = false;
        this.maxMonth = false;
        this.inBetweenMonth = false
      
    },
    async createAcademicYear() {
        let isFormValid = false;
        let message = "Please fill all the required details";

        await this.$validator.validateAll('academicyr').then(result => {
          isFormValid = result;
        });
        if(isFormValid) {
          if(!this.minMonth && !this.maxMonth && !this.inBetweenMonth){
            let userData = secureUI.sessionGet("user");

            if (!userData) {
              this.$toasted.error("Please login and do the action");
              this.$router.push('/login');
            } else {
              this.viLoader = true;

              if (this.academicYear.from != null && this.academicYear.to != null) {
                this.academicYear.from = this.academicYear.from._i;
                this.academicYear.to = this.academicYear.to._i;
                const response = await ViService.viXPost(
                  "/academicYear/createAcademicYear",
                  this.academicYear,
                  userData.token
                );

                this.viLoader = null;

                if (response.isSuccess) {
                  this.closePoup();
                  this.$bvModal.hide("addacademicyr");
                  if(this.$route.path != '/admission/academicYear'){
                    this.$emit("callAcademicYearList");
                  }
                  this.countAll = true
                  this.getAcademicYearList('old');

                  let getCounts = this.$store.getters.getAdmissionObjCounts
                  getCounts.academicYear = getCounts.academicYear + 1

                  this.$store.dispatch('setAdmissionObjCOunt', getCounts);
                  
                  this.$toasted.success(response.message);
                } else {
                  this.showErrorMsg = false
                  this.academicYear._id = null;
                  this.academicYear.from = null;
                  this.academicYear.to = null;
                  this.$toasted.error(response.message);
                  if(response.message == 'Your session has expired, please login'){
                      localStorage.removeItem('user');
                      this.$router.push('/login');
              }
                }
              } else {
                this.$toasted.error("field should not be blank");
              }
            }
        }
        }else{
          this.showErrorMsg = true
          this.$toasted.error(message);
        }
    },

    async updateAcademicYear() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        let isFormValid = false;
        let message = "Please fill all the required details";

        await this.$validator.validateAll('academicyr').then(result => {
          isFormValid = result;
        });
        if(isFormValid) {

        if(!this.minMonth && !this.maxMonth && !this.inBetweenMonth){
          if (this.academicYear.from != null && this.academicYear.to != null) {


      let obj = {
        from: this.academicYear.from._i || this.academicYear.from,
        to: this.academicYear.to._i || this.academicYear.to,
        _id: this.academicYear._id
      }
   


      //  // this.viLoader = true;
      //   this.academicYear.from = this.academicYear.from._i;
      //   this.academicYear.to = this.academicYear.to._i;


        const response = await ViService.viXPut(
          `/academicYear/updateAcademicYear/${obj._id}`,
          obj,
          userData.token
        );
        
       // this.viLoader = null;

        if (response.isSuccess) {
         if(this.currentAcademicYear._id == obj._id){
          const jsonString = JSON.stringify(obj);

          localStorage.setItem("currentAcademicYear",obj._id);

          this.$store.dispatch('setCurrentAcademicYear', obj);
          userData.userDetails.account.currentAcademicYear = obj
         }

          this.closeUpdateModal();

          if(this.$route.path != '/admission/academicYear'){
               this.$emit("callAcademicYearList");
          }
          this.countAll = true
          this.getAcademicYearList('old');
          this.academicYear._id = null;
          this.academicYear.from = null;
          this.academicYear.to = null;
          this.$toasted.success(response.message);
        } else {

          // this.academicYear.from._i = this.academicYear.from;
          // this.academicYear.to._i = this.academicYear.to;

          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }
     }else{
          this.showErrorMsg = true
          this.$toasted.error(message);
        }
       // if(!this.minMonth && !this.maxMonth && !this.inBetweenMonth){

      }
      
    },

    async navToSelectedPage(selectedPageNo) {

      this.skip = (this.limit * (selectedPageNo - 1));

      this.countAll = false

      this.getAcademicYearList('old');
    },

    async goToNextPage() {
      this.countAll = false
      this.currentPageNumber = this.currentPageNumber + 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));

      this.getAcademicYearList('old');
    },

    async goToPreviousPage() {
       this.countAll = false
      this.currentPageNumber = this.currentPageNumber - 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));
     
      this.getAcademicYearList('old');
    },

    closePoup() {
      this.$bvModal.hide("addacademicyr");
      this.academicYear._id = null;
      this.academicYear.from = null;
      this.academicYear.to = null;
      this.minMonth  = false,
      this.maxMonth = false
      this.showErrorMsg = false
     // inBetweenMonth: false,
      
    },

    closeUpdateModal() {
      this.$bvModal.hide("addacademicyr");
      this.isUpdate = false;
      this.academicYear._id = null;
      this.academicYear.from = null;
      this.academicYear.to = null;
      this.minMonth  = false,
      this.maxMonth = false
    },

    async getAcademicYearList(type) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
         this.$router.push('/login');
      } else {
        if(type == 'new') {
         this.viLoader = true;
       }
       //  this.viLoader = true;
        const response = await ViService.viXGet(
          `/academicYear/getAcademicYearListView?skip=${this.skip}&limit=${this.limit}&search=${this.searchWords}&isCount=${this.countAll}`,
          userData.token
        );
        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.academicYearList = resultData.academicYearList
          if(this.countAll){
            this.totalCount = resultData.totalCount;
            
            this.lastPageNumber = Math.ceil(this.totalCount/this.limit);
          }
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }

         this.viLoader = false
       
      }
    },
    async setCurrentAcademicYearActive(id) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let obj = {
         // currentAcademicYear: this.currentAcademicYear,
          _id: id,
        };

        const response = await ViService.viXPut(
          `/academicYear/updateCurrentAcademicYear`,
          obj,
          userData.token
        );

        // const response = await ViService.viXPut(
        //   "/department/updateDepartmentConfig",
        //   obj,
        //   userData.token
        // );

        if (response.isSuccess) {
          let data = this.academicYearList.find((x) => x._id == id);

          //const jsonString = JSON.stringify(data);

          localStorage.setItem("currentAcademicYear",data._id);

          this.$store.dispatch("setCurrentAcademicYear", data);
          this.getAcademicYearList('old');
          this.$toasted.success(response.message);

         // this.getAccountDetails();
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    closeDeletePopUp(){
      this.$bvModal.hide("deleteAcademicYearPopup");
      this.deleteId = null;
    },
    closeConcludePopUp(){
      this.$bvModal.hide("concludeAcademicYearPopup");
      this.concludeId = null;
    },
    
    async confirmDelete() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        //this.viLoader = true;
        const response = await ViService.viDelete(
          `/academicYear/deleteAcademicYear/${this.deleteId}`,
          userData.token
        );

        if (response.isSuccess) {
         
          this.countAll = true
          this.getAcademicYearList('old');
          this.$toasted.success(response.message);
           let getCounts = this.$store.getters.getAdmissionObjCounts
            getCounts.academicYear = getCounts.academicYear - 1

            this.$store.dispatch('setAdmissionObjCOunt', getCounts);
          this.closeDeletePopUp();
           // this.$bvModal.hide("deleteAcademicYearPopup");
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      this.viLoader = false;
    },
    async confirmConclude() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        //this.viLoader = true;
        const response = await ViService.viDelete(
          `/academicYear/concludeAcademicYear/${this.concludeId}`,
          userData.token
        );

        if (response.isSuccess) {
         
        //  this.countAll = true
          this.getAcademicYearList('old');
          this.$toasted.success(response.message);
         

         // this.$store.dispatch('setAdmissionObjCOunt', getCounts);
          this.closeConcludePopUp();
           // this.$bvModal.hide("deleteAcademicYearPopup");
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      this.viLoader = false;
    },
    async confirmActiveConclude() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        //this.viLoader = true;
        const response = await ViService.viDelete(
          `/academicYear/confirmActiveConcludeAcademicYear/${this.concludeId}`,
          userData.token
        );

        if (response.isSuccess) {
         
        //  this.countAll = true
          this.getAcademicYearList('old');
          this.$toasted.success(response.message);
         

         // this.$store.dispatch('setAdmissionObjCOunt', getCounts);
          this.closeActiveConcludePopUp();
           // this.$bvModal.hide("deleteAcademicYearPopup");
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      this.viLoader = false;
    },
  },
};
</script>
<style>
/* .model-dialog */
/* .modal-content {
    width: 10% !important; /* Could be more or less, depending on screen size */
	
  #concludeActiveAcademicYearPopup .modal-content {
    width: 37% !important; /* Could be more or less, depending on screen size */
	
}
</style>